const { useState, useEffect } = require("react")
import Posts from './Posts';
import Pagination from './Pagination';
import _ from 'lodash';

const CustomPagination = ({ data, showPerPage, currentPage, currentPosts, setCurrentPage }) => {

    const [nextClass, setNextClass] = useState(currentPosts?.length > showPerPage ? true : false);
    const [prevClass, setPreClass] = useState(true);
    const current = _.reject(data, (row) => row.is_approved !== true).slice((currentPage + 1) * showPerPage - showPerPage, (currentPage + 1) * showPerPage);

    useEffect(() => {
        if (current?.length >= 1) {
            setNextClass(false);
        } else {
            setNextClass(true);
        }
        if (currentPage === 1) {
            setPreClass(true);
        } else {
            setPreClass(false);
        }
    }, [current, currentPage])

    // next button click
    const nextButton = () => {
        if (current?.length >= 1) {
            setNextClass(false);
            setCurrentPage(currentPage + 1)
        }
    };
    const previousButton = () => {
        if (currentPage > 1) {
            //setPreClass(false);
            setCurrentPage(currentPage - 1);
        }

    }

    return (
        <>
            <div className='container mt-5'>
                {/* <Posts posts={currentPosts} loading={loading} /> */}
                <Pagination
                    prevClass={prevClass}
                    nextClass={nextClass}
                    postsPerPage={showPerPage}
                    totalPosts={data?.length}
                    nextButton={nextButton}
                    previousButton={previousButton}
                />
            </div>
        </>)
}
export default CustomPagination;