import React, { useState, useEffect, forwardRef } from 'react';

const AccordionComp = forwardRef(({ title, content, active }, ref) => {
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        let check = active ? true : false;
        setIsActive(check);
    }, [])

    useEffect(() => {
        setIsActive(active);
    }, [])

    return (
        <div className="accordion-item" id="content-more" >
            <div className="accordion-title" onClick={() => setIsActive(!isActive)} >
                <div className='icon-accor'>{isActive ? '-' : '+'}</div>
                <div className='accord-head'>{title}</div>
            </div>
            {isActive &&
                <div className="accordion-content" ref={ref}>{content}</div>
            }
        </div>
    );
});

export default AccordionComp;